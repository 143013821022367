<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/expense`"
                title="Expense"
                :columns="columns"
                routerpath="/crm/expense/addexpense"
                :formOptions="formOptions"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import moment from "moment";
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Employee Name",
          field: "employee",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Employee Name",
          },
        },
        {
          label: "Activity Type",
          field: "activitytype",
          filterOptions: {
            enabled: true,
            placeholder: "Search Activity Type",
          },
        },
        {
          label: "Date",
          field: "date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Place / Destination",
          field: "place",
          filterOptions: {
            enabled: true,
            placeholder: "Search Place / Destination",
          },
        },
        {
          label: "KM",
          field: "km",
          filterOptions: {
            enabled: true,
            placeholder: "Search KM",
          },
        },
        {
          label: "Travel Fare",
          field: "travelfare",
          filterOptions: {
            enabled: true,
            placeholder: "Search Travel Fare",
          },
        },
        {
          label: "Local Conveyance",
          field: "localconveyance",
          filterOptions: {
            enabled: true,
            placeholder: "Search Local Conveyance",
          },
        },
        {
          label: "Food Expenses",
          field: "foodexpense",
          filterOptions: {
            enabled: true,
            placeholder: "Search Food Expenses",
          },
        },
        {
          label: "Hotel Expenses",
          field: "hotelexpense",
          filterOptions: {
            enabled: true,
            placeholder: "Search Hotel Expenses",
          },
        },
        {
          label: "Other Expenses",
          field: "otherexpense",
          filterOptions: {
            enabled: true,
            placeholder: "Search Other Expenses",
          },
        },
        {
          label: "Details of Other Expenses",
          field: "otherexpensedetails",
          filterOptions: {
            enabled: true,
            placeholder: "Search Details of Other Expenses",
          },
        },
        {
          label: "Remarks",
          field: "remarks",
          filterOptions: {
            enabled: true,
            placeholder: "Search Remarks",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",

          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
         {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",

          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      viewFields: [
        {
          label: "Employee Name",
          field: "employee_id",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Date",
          field: "date",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Place Destination",
          field: "place",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "KM",
          field: "km",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Local Conveyance",
          field: "localconveyance",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Food Expenses",
          field: "foodexpense",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Hotel Expenses",
          field: "hotelexpense",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Travel Fare",
          field: "travelfare",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Other Expenses",
          field: "otherexpense",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Details of Other Expenses",
          field: "otherexpensedetails",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "Remarks",
          field: "remarks",
          type: "text",
          class: "col-md-4",
        },
        {
          label: "ducuments",
          field: "ExpenseList",
          tableHead: ["Attachment Name", "Attachment File"],
          tableField: [
            {
              type: "text",
              field: "attachmentname",
            },
            {
              type: "attachment",
              field: "attachmentfile",
            },
          ],
          type: "multiple",
          class: "col-md-12",
        },
      ],
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Expense" : "Add Expense"}`,
        getEditValue: `${baseApi}/getexpenseById`,
        submitRouterPath: "/crm/expense",
        usersDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/expense`,
        selectedItemId: null,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Title",
            name: "title",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "title",
            options: [
              { name: "DR." },
              { name: "MRS." },
              { name: "M/S." },
              { name: "MS." },
              { name: "Mr." },
              { name: "Er." },
              { name: "Prof." },
              { name: "Shri" },
            ],
          },
          {
            label: "Ledger Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Ledger Name",
            class: "col-md-6",
          },
          {
            label: "Mobile",
            name: "mobile",
            value: "",
            type: "number",
            placeholder: "Enter Mobile",
            class: "col-md-6",
          },
          {
            label: "Email",
            name: "email",
            value: "",
            type: "email",
            placeholder: "Enter Mobile",
            class: "col-md-6",
          },
          {
            label: "Alias",
            name: "alias",
            value: "",
            type: "text",
            placeholder: "Enter Alias",
            class: "col-md-6",
          },
          {
            label: "Registration Type",
            name: "registrationtype",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "registrationtype",
            options: [
              { name: "None" },
              { name: "Composition" },
              { name: "Regular" },
              { name: "Customer" },
              { name: "Unregisterd" },
            ],
          },
          {
            label: "GST No",
            name: "gstno",
            value: "",
            type: "text",
            placeholder: "Enter GST No",
            class: "col-md-6",
          },
          {
            label: "Pan No.",
            name: "panno",
            value: "",
            type: "text",
            placeholder: "Enter Pan No.",
            class: "col-md-6",
          },
          {
            label: "Birth Date",
            name: "birthdate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Enter Birth date",
            class: "col-md-6",
          },
          {
            label: "Swift Code",
            name: "chequeno",
            value: "",
            type: "text",
            placeholder: "Enter Swift Code",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "IFSC Code",
            name: "ifsccode",
            value: "",
            type: "text",
            placeholder: "Enter IFSC Code",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Bank",
            name: "bank",
            value: "",
            type: "text",
            placeholder: "Enter Bank Name",
            class: "col-md-6",
          },
          {
            label: "Branch",
            name: "branch",
            value: "",
            type: "text",
            placeholder: "Enter Branch",
            class: "col-md-6",
          },
          {
            label: "Account No",
            name: "accountno",
            value: "",
            type: "text",
            placeholder: "Enter Account No",
            class: "col-md-6",
          },
          {
            label: "Tan No",
            name: "tanno",
            value: "",
            type: "text",
            placeholder: "Enter Tan No",
            class: "col-md-6",
          },
          {
            label: "Contry",
            name: "contry",
            value: "",
            type: "text",
            placeholder: "Enter Country Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "TDS%",
            name: "tds",
            value: "",
            type: "number",
            placeholder: "Enter TDS %",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Address Line 1",
            name: "adr1",
            value: "",
            type: "text",
            placeholder: "Enter Address Line 1",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Address Line 2",
            name: "adr2",
            value: "",
            type: "text",
            placeholder: "Enter Address Line 2",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Address Line 3",
            name: "adr3",
            value: "",
            type: "text",
            placeholder: "Enter Address Line 3",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Address Line 4",
            name: "adr4",
            value: "",
            type: "text",
            placeholder: "Enter Address Line 4",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Address Line 5",
            name: "adr5",
            value: "",
            type: "text",
            placeholder: "Enter Address Line 5",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Area",
            name: "area",
            value: "",
            type: "text",
            placeholder: "Enter Area",
            class: "col-md-6",
            required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Pin Code",
            name: "pincode",
            value: "",
            type: "text",
            placeholder: "Enter Pincode",
            class: "col-md-6",
            required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-6",
            required: true,
          },
          {
            label: "Active/Inactive",
            name: "activeinactive",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "activeinactive",
            options: [{ name: "Active" }, { name: "Inactive" }],
            // required: {
            //   required,
            // },
          },
          {
            label: "Contact Person Name",
            name: "contactname",
            value: "",
            type: "text",
            placeholder: "Enter Contact Person Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Contact Person Number",
            name: "contactnumber",
            value: "",
            type: "number",
            placeholder: "Enter Contact Person Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Credit period(In Days)",
            name: "creditperiod",
            value: "",
            type: "text",
            placeholder: "Enter Credit Period In Days",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "RCM",
            name: "rcm",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "rcm",
            options: [{ name: "Yes" }, { name: "No" }],
            // required: {
            //   required,
            // },
          },
          {
            label: "Select Employee",
            name: "employee",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "employee",
            options: [],
            // required: {
            //   required,
            // },
          },
          {
            label: "Username",
            name: "username",
            value: "",
            type: "text",
            placeholder: "Enter Username",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Password",
            name: "password",
            value: "",
            type: "text",
            placeholder: "Enter Password",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Photo",
            name: "photo",
            value: "",
            type: "fileinput",
            placeholder: "select Photo",
            class: "col-md-6",
            // required: true,
          },
        ],
      },

      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Expense") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
